<template>
    <div class="g-section  edit-wrap">
        <div class="g-wrap flex-column">
            <content-header :titleList="titleList">
                <div slot="btn" style="margin-right:-120px;">
                    <el-button type="primary" plain @click="changePassword">修改密码</el-button>
                </div>
            </content-header>
            <div class="from-content">
                <el-form v-if="form" ref="form" :model="form" :rules="rules" label-width="66px" class="width100">
                    <el-form-item label="头像">
                        <picture-selector v-model="form.Avatar" name="Avatar" ref="Avatar" remarks="200*200" uploadPath="/DataCenterFile/UploadSingleFile" :width="100" :height="100" @input="updateForm">
                        </picture-selector>
                    </el-form-item>
                    <el-form-item label="昵称" prop="NickName">
                        <el-input v-model="form.NickName"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱">
                        <el-input v-model="form.Email"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号">
                        <div class="input-text" v-text="form.Phone ? form.Phone : '暂无'"></div>
                    </el-form-item>
                    <el-form-item>
                        <!-- <el-button>取消</el-button> -->
                        <el-button type="primary" @click="save">保存</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <el-dialog title="修改密码" :visible.sync="dialogFormVisible" :close-on-press-escape="false" :close-on-click-modal="false">
            <el-form ref="passwordForm" status-icon :model="passwordForm" :rules="passwordFormRule">
                <el-form-item label="旧密码" label-width="120px" prop="OldPassword">
                    <el-input type="password" v-model="passwordForm.OldPassword" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="新密码" label-width="120px" prop="NewPassword">
                    <el-input type="password" v-model="passwordForm.NewPassword" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认新密码" label-width="120px" prop="ConfirmNewPassword">
                    <el-input type="password" v-model="passwordForm.ConfirmNewPassword" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitPassword">确 定</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import PictureSelector from "@/components/base/PictureSelector";
    import ContentHeader from "@/components/Common/ContentHeader.vue";
    import { GetUserInfo, UpdateUserInfo, changePassword } from "@/api/user";
    import { mapActions } from "vuex";
    export default {
        components: { PictureSelector, ContentHeader },
        data() {

            var validateNewPassword = (rule, value, callback) => {
                if (!/^[0-9a-zA-Z]{6,15}$/.test(value)) {
                    callback(new Error('请输入密码(大写字母、小写字母、数字，6-15位)'));
                } else if (value === this.passwordForm.OldPassword) {
                    callback(new Error('不能和原密码一样'));
                } else {
                    callback();
                }
            };
            var validateConfirmNewPassword = (rule, value, callback) => {
                if (!/^[0-9a-zA-Z]{6,15}$/.test(value)) {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.passwordForm.NewPassword) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };

            return {
                titleList: [{
                    text: "账号信息",
                    isCur: true,
                    link: ""
                }],
                userInfo: this.$store.getters,
                url: process.env.VUE_APP_BASE_API + "/BO/FileManage/UploadSingleFile",
                headers: { Authorization: this.$store.getters.token },
                form: {},
                rules: {
                    NickName: [{ required: true, message: "昵称不能为空", trigger: "blur" }]
                },
                passwordForm: {
                    OldPassword: undefined,
                    NewPassword: undefined,
                    ConfirmNewPassword: undefined
                },
                passwordFormRule: {
                    OldPassword: [{ required: true, message: "密码不能为空", trigger: "blur" }],
                    NewPassword: [
                        { required: true, validator: validateNewPassword, trigger: 'blur' }
                    ],
                    ConfirmNewPassword: [
                        { required: true, validator: validateConfirmNewPassword, trigger: 'blur' }
                    ],
                },
                dialogFormVisible: false
            };
        },
        mounted() {
            GetUserInfo(this.$store.getters.userId).then(res => {
                if (res.IsSuccess) {
                    this.form = res.Result;
                }
            });
        },
        methods: {
            ...mapActions("oidc", {
                signOut: "signOutOidc"
            }),
            updateForm(fieldName, value) {
                console.log("修改参数:", fieldName + "值：", value);
                this.form[fieldName] = value;
            },
            //  保存修改
            save() {
                UpdateUserInfo(this.form).then(res => {
                    if (res.IsSuccess) {
                        this.$message({
                            message: "修改成功",
                            type: "success"
                        });
                        this.$store.dispatch("oidc/authenticateOidcSilent");
                    }
                });
            },
            changePassword() {
                this.dialogFormVisible = true;
            },
            submitPassword() {
                this.$refs.passwordForm.validate((valid) => {
                    if (valid) {
                        changePassword(this.passwordForm).then((r) => {
                            this.dialogFormVisible = false;
                            alert("修改成功，即将退出，请重新登录");
                            this.signOut();
                        }).catch((c) => {
                            this.$message.error('修改失败');
                        })
                    } else {
                        this.$message.error('输入有误');
                        return false;
                    }
                });
            }
        }
    };
</script>

<style lang="scss">
    .from-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        box-sizing: border-box;
        padding-top: 20px;
        background: #fff;
        border-radius: 8px;
        padding: 48px 122px;
        margin-bottom: 16px;
        min-height: 400px;

        .title {
            font-size: 18px;
            width: 1100px;
            text-align: left;
            line-height: 48px;
            font-weight: bold;
        }

        .account-cont {
            background-color: #fff;
            width: 1100px;
            padding: 40px 20px 20px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            box-shadow: 0 3px 6px 0 #999;
            border-radius: 4px;
            min-height: 500px;
        }

        .input-text {
            color: #666;
            line-height: 40px;
            padding-left: 15px;
        }
    }
</style>